<div *ngIf="selectedPdf" class="container">
    <div class="header">
      {{title}}
      <button class="btn btn-primary download-btn" (click)="downloadPdf()" *ngIf="isPiTags()">
        <span class="material-icons" aria-hidden="true">cloud_download</span> Download file
      </button>
    </div>

    <div *ngIf="isLoaded" class="pagination">
        <button class="element" (click)="prevPage()" [disabled]="page === 1"><span class="material-icons custom-material-icons">
                navigate_before
            </span></button>
        <button class="element-central"> {{page}} of {{totalPages}} </button>
        <button class="element" (click)="nextPage()" [disabled]="page === totalPages"><span class="material-icons custom-material-icons">
                navigate_next
            </span></button>

    </div>
    <pdf-viewer [src]="selectedPdf" [original-size]="false" [show-all]="false" (after-load-complete)="callBackFn($event)"
        [page]="page">
    </pdf-viewer>

    <div *ngIf="isLoaded" class="pagination">
        <button class="element" (click)="prevPage()" [disabled]="page === 1"><span class="material-icons custom-material-icons">
                navigate_before
            </span></button>
        <button class="element-central"> {{page}} of {{totalPages}} </button>
        <button class="element" (click)="nextPage()" [disabled]="page === totalPages"><span class="material-icons custom-material-icons">
                navigate_next
            </span></button>

    </div>
</div>
